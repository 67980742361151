<template>
    <Toast />
    <h2>User Management</h2>
    <div class="card">
        <div class="grid col-9">
            <div class="field col-5">
                <label>Email ID&nbsp;</label>
                <InputText id="customer" placeholder="email.id@domain.com" v-model="IndividualUser" type="text" style="width:80%" />
            </div>
            <div class="col-3">
                <Button label="Add User" :disabled="!IndividualUser" @click="AddUser" icon="pi pi-save" class="p-button-success" />
            </div>
        </div>
    </div>
    <div v-if="loading_value">
        <i class="pi pi-spin pi-spinner" style="font-size: 1.3em; float: left;"></i>
        <p class="Loading"><i>&nbsp;{{loading_value}}</i></p>
    </div>
</template>
<script>
import FormulatorService from '../tipureServices/FormulatorService';
export default{
    data(){
        return{
            loading_value:null,
            IndividualUser:null,
        };
    },
    created() {
        this.formulatorService = new FormulatorService();
        this.loading = false;
    },
    methods: {
        AddUser(){            
            this.loading_value="Adding User, please wait...";

            this.formulatorService.enroleUser(this.IndividualUser,false).then((data) => {
                if (data) {
                    this.loading_value=false;
                    this.IndividualUser=""
                    console.log(data);
                    setTimeout(() => {
                    this.$router.push({ path: '/admin/newModel' });
                        }, 500);
                    this.$toast.add({ severity: 'success', summary: 'Added New user', detail: data, life: 3000 });
                }
                }).catch(err => {
                    this.$toast.add({ severity: 'error', summary: "INTERNAL SERVER ERROR", detail: err, life: 5000 });
                    this.loading_value=false;
                });
        }
    }
}
</script>
<style scoped>

</style>